import {useUserStore} from "~/stores/user"
import {RouteLocationNormalized} from "vue-router"

function useDefaultLayout(to: RouteLocationNormalized) {
    to.meta.userIsAnonymousStudent = false
    setPageLayout('default')
}

function useMinimalLayout(to: RouteLocationNormalized) {
    to.meta.userIsAnonymousStudent = true
    setPageLayout('minimal')
}

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (useUserStore().isAuthenticated) {
        return useDefaultLayout(to)
    }

    if (to.name && (to.name.toString().startsWith('media-id-slug') || to.name.toString().startsWith('capture-code'))) {
        return (to.query.dir || to.query.auth) ? useMinimalLayout(to) : useDefaultLayout(to)
    }

    useMinimalLayout(to)
})
